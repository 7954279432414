<template>
  <title-coaching title="Historique des factures"/>
  <div class="table-container invoice-list">
    <div>
      <div class="table">
        <div class="line">
          <div class="column bold">FA2102021</div>
          <div class="column">650,00 €</div>
          <div class="column">25/02/2021</div>
          <div class="column"><a href="#">Télécharger la facture</a></div>
        </div>
        <div class="line">
          <div class="column bold">FA2102021</div>
          <div class="column">650,00 €</div>
          <div class="column">25/02/2021</div>
          <div class="column"><a href="#">Télécharger la facture</a></div>
        </div>
        <div class="line">
          <div class="column bold">FA2102021</div>
          <div class="column">650,00 €</div>
          <div class="column">25/02/2021</div>
          <div class="column"><a href="#">Télécharger la facture</a></div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="showMore">
            <div class="line">
              <div class="column bold">FA2102021</div>
              <div class="column">650,00 €</div>
              <div class="column">25/02/2021</div>
              <div class="column"><a href="#">Télécharger la facture</a></div>
            </div>
            <div class="line">
              <div class="column bold">FA2102021</div>
              <div class="column">650,00 €</div>
              <div class="column">25/02/2021</div>
              <div class="column"><a href="#">Télécharger la facture</a></div>
            </div>
            <div class="line">
              <div class="column bold">FA2102021</div>
              <div class="column">650,00 €</div>
              <div class="column">25/02/2021</div>
              <div class="column"><a href="#">Télécharger la facture</a></div>
            </div>
            <div class="line">
              <div class="column bold">FA2102021</div>
              <div class="column">650,00 €</div>
              <div class="column">25/02/2021</div>
              <div class="column"><a href="#">Télécharger la facture</a></div>
            </div>
          </div>
        </transition>
      </div>
      <div class="actions" v-if="showMoreLink">
        <a href="#" @click="showMore = !showMore;showMoreLink = !showMoreLink">Voir plus</a>
      </div>
    </div>
  </div>
</template>

<script>
import TitleCoaching from "@/components/Coaching/TitleCoaching";

export default {
  name: "AnonymousMeetingList",
  data() {
    return {
      showMore: false,
      showMoreLink: true
    }
  },
  components: {
    TitleCoaching
  },
  computed: {

  }
}
</script>